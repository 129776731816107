import css from './Selectbar.module.scss';
import { Select } from 'components/Select/Select';
import {
  regionsList,
  regionSelect,
  citiesSelect,
  citySelect,
  replaceRegion,
  replaceCity,
} from 'constants/regions';
import { useEffect, useState } from 'react';
import { mediaSizes } from 'constants/constants';
import { RxDoubleArrowDown, RxDoubleArrowUp } from 'react-icons/rx';
import { ReactComponent as Location } from 'icons/location.svg';
import { ReactComponent as Doublelocation } from 'icons/doublelocation.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRegion,
  selectCity,
  setCities,
} from 'redux/slices/location/locationSlice';

export const Selectbar = () => {
  const initialRegion = replaceRegion(
    useSelector(state => state.location.region)
  );
  const initialCity = replaceCity(
    useSelector(state => state.location.selectedCity)
  );
  const region = useSelector(state => state.location.region);
  const cities = useSelector(state => state.location.cities);
  const [isSelectionShown, setIsSelectionShown] = useState(true);
  const [isOpenButtonShown, setIsOpenButtonShown] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseInt(mediaSizes.desktop)) {
        setIsSelectionShown(true);
        setIsOpenButtonShown(true);
      } else {
        setIsSelectionShown(true);
        setIsOpenButtonShown(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setSelectedRegion = value => {
    if (value === 'Все регионы') {
      dispatch(selectRegion('All'));
      dispatch(selectCity('All'));
      dispatch(setCities([]));
    } else {
      dispatch(selectCity('All'));
      const selectedRegion = regionSelect(value);
      if (selectedRegion !== region) {
        const selectedCities = citiesSelect(selectedRegion);
        dispatch(selectRegion(selectedRegion));
        dispatch(setCities(selectedCities));
      }
    }
  };

  const setSelectedCity = value => {
    const city = citySelect(value);
    dispatch(selectCity(city));
  };

  const clickHandler = () => {
    setIsSelectionShown(state => !state);
  };

  return (
    <section className={css.selectbar}>
      <div className={css.container}>
        {!isSelectionShown && (
          <p className={css.title}>Выбор региона и города</p>
        )}
        {isOpenButtonShown && (
          <button type="button" className={css.btn} onClick={clickHandler}>
            {isSelectionShown ? (
              <RxDoubleArrowUp className={css.arrow} />
            ) : (
              <RxDoubleArrowDown className={css.arrow} />
            )}
          </button>
        )}
        {isSelectionShown && (
          <div className={css.wrapper}>
            <Select
              icon={<Location />}
              title="Регион"
              name="region"
              width={!isOpenButtonShown ? '45%' : '90%'}
              data={regionsList}
              updateGlobalState={setSelectedRegion}
              initialValue={initialRegion}
              open={openSelect === 'region'}
              onToggleOpen={() => setOpenSelect('region')}
            />
            <Select
              icon={<Doublelocation />}
              title="Населенный пункт"
              name="city"
              width={!isOpenButtonShown ? '45%' : '90%'}
              data={cities}
              updateGlobalState={setSelectedCity}
              initialValue={initialCity}
              open={openSelect === 'city'}
              onToggleOpen={() => setOpenSelect('city')}
            />
          </div>
        )}
      </div>
    </section>
  );
};
