export const showRating = (rating, estimation) => {
  const range = [1, 2, 3, 4, 5];
  const int = Math.floor(rating);
  const fraction = rating - int;

  return rating > 0 ? (
    <>
      {estimation && (
        <p style={{ display: 'flex', justifyContent: 'center' }}>
          Рейтинг: {rating}
        </p>
      )}
      <div className="ratingWrapper">
        {range.map((_, index) => {
          let className = '';
          let style = {};

          if (index < int) {
            className = 'ratingActive';
          } else if (index === int && fraction > 0) {
            className = 'ratingPartial';
            style = {
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              border: '1px solid #ea0707',
              backgroundImage: `linear-gradient( to right, #ea0707 ${
                fraction * 100
              }%, #fff ${fraction * 100}%)`,
            };
          } else {
            className = 'ratingUnactive';
          }

          return <span key={index} style={style} className={className}></span>;
        })}
      </div>
    </>
  ) : null;
};
