import { createSlice } from '@reduxjs/toolkit';
import { adminLogin, getStats, getAdvertise } from './operations';

const initialData = {
  isAdminLogged: false,
  isLoading: false,
  token: '',
  stats: {},
  advertise: [],
  error: '',
  status: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialData,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    extraReducers: builder => {
      builder
        .addCase(adminLogin.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(adminLogin.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isAdminLogged = true;
          state.token = action.payload.token;
        })
        .addCase(adminLogin.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload.data.message;
        })
        .addCase(getStats.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(getStats.fulfilled, (state, action) => {
          state.isLoading = false;
          state.stats = action.payload;
        })
        .addCase(getStats.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload.data.message;
          if (action.payload.status === 401) {
            state.status = true;
          }
        })
        .addCase(getAdvertise.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(getAdvertise.fulfilled, (state, action) => {
          state.isLoading = false;
          state.advertise = action.payload;
        })
        .addCase(getAdvertise.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload.data.message;
          if (action.payload.status === 401) {
            state.status = true;
          }
        });
    },
  },
});

export const { setStatus } = adminSlice.actions;
export const adminReducer = adminSlice.reducer;
