export const toTitleCase = (str, onlyFirstWord = true) => {
  if (!str) return '';

  const adjustWord = word => word.charAt(0).toUpperCase() + word.slice(1);

  if (onlyFirstWord) {
    return adjustWord(str);
  } else {
    return str.split(' ').map(adjustWord).join(' ');
  }
};
