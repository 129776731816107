import { useState, useRef } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { BiMessage } from 'react-icons/bi';
import { ReactComponent as Like } from 'icons/like.svg';
import { CgProfile } from 'react-icons/cg';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDataShown } from 'redux/slices/auth/authSlice';
import css from './MobileNavbar.module.scss';

export const MobileNavbar = () => {
  const [isMessagesShown, setIsMessagesShown] = useState(true);

  const userId = useSelector(state => state.auth.user._id);
  const { unreadMessages } = useSelector(state => state.announcements);
  const dispatch = useDispatch();
  const navbarRef = useRef(null);

  const clickHandler = () => {
    dispatch(setIsDataShown(false));
  };

  const dataShowHandler = () => {
    dispatch(setIsDataShown(true));
  };

  const showMessagesHandler = () => {
    dispatch(setIsDataShown(true));
    setIsMessagesShown(false);
  };

  return (
    <ul className={css.list} ref={navbarRef}>
      <li className={css.item}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? `${css.active}` : `${css.link}`
          }
          onClick={dataShowHandler}
        >
          <AiOutlineHome /> Главная
        </NavLink>
      </li>
      <li className={css.item}>
        <NavLink
          to={`/users/${userId}/profile/announcements/selected`}
          className={({ isActive }) =>
            isActive ? `${css.active}` : `${css.link}`
          }
          onClick={dataShowHandler}
        >
          <Like /> Избранные
        </NavLink>
      </li>
      <li className={css.item}>
        <NavLink
          to={`/users/${userId}/profile/announcements/new`}
          className={({ isActive }) =>
            isActive ? `${css.active}` : `${css.link}`
          }
          onClick={showMessagesHandler}
        >
          <IoMdAddCircleOutline /> Создать
        </NavLink>
      </li>
      <li className={`${css.item} ${css.message}`}>
        <NavLink
          to={`/users/${userId}/profile/conversations/sell`}
          className={({ isActive }) =>
            isActive ? `${css.active}` : `${css.link}`
          }
          onClick={dataShowHandler}
        >
          <BiMessage /> Сообщения
          {unreadMessages.sell + unreadMessages.buy > 0 && isMessagesShown && (
            <span className={css.unread}>
              {unreadMessages.sell + unreadMessages.buy}
            </span>
          )}
        </NavLink>
      </li>
      <li className={css.item}>
        <NavLink
          end={true}
          to={`/users/${userId}/profile`}
          className={({ isActive }) =>
            isActive ? `${css.active}` : `${css.link}`
          }
          onClick={clickHandler}
        >
          <CgProfile /> Профиль
        </NavLink>
      </li>
    </ul>
  );
};
