export const getWord = (num, wordType) => {
  let single, few, many;

  if (wordType === 'review') {
    single = 'отзыв';
    few = 'отзыва';
    many = 'отзывов';
  } else if (wordType === 'answer') {
    single = 'ответ';
    few = 'ответа';
    many = 'ответов';
  } else {
    return '';
  }

  if (num === 0) {
    return `нет ${many}`;
  } else if (num === 1) {
    return single;
  } else if (num > 1 && num < 5) {
    return few;
  } else {
    return many;
  }
};
