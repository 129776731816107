import { createSlice } from '@reduxjs/toolkit';
import {
  register,
  login,
  logout,
  verify,
  restorePassword,
  saveAvatar,
  saveContacts,
  checkRestoreKey,
  resetPassword,
} from './operations';

const initialState = {
  user: {},
  posts: null,
  isLoggedIn: false,
  isLoading: false,
  isModalOpen: false,
  message: null,
  status: null,
  isDataShown: true,
  isNoticeShown: false,
  token: null,
  warning: '',
  error: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setVerify: (state, action) => {
      state.verify = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setNewAnnouncement: (state, action) => {
      state.user.announcements = [
        ...state.user.announcements,
        ...action.payload,
      ];
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setIsDataShown: (state, action) => {
      state.isDataShown = action.payload;
    },
    setIsNoticeShown: (state, action) => {
      state.isNoticeShown = action.payload;
    },
    setWarning: (state, action) => {
      state.warning = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setContacts: (state, action) => {
      state.user.contacts = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isNoticeShown = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.status = 'success';
        state.message = 'Вы успешно вошли!';
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = {};
        state.token = null;
        state.isLoggedIn = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(verify.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.message = 'Пароль успешно изменен!';
        state.status = 'success';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(restorePassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(restorePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message =
          'Вам на почту отправлено письмо! Перейдите по ссылке в письме, чтобы восстановить пароль';
        state.status = 'success';
      })
      .addCase(restorePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(checkRestoreKey.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkRestoreKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(checkRestoreKey.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveAvatar.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveAvatar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.avatarURL = action.payload.avatarURL;
      })
      .addCase(saveAvatar.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(saveContacts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.contacts = action.payload;
        state.message = 'Контакты успешно сохранены';
        state.status = 'success';
      })
      .addCase(saveContacts.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      });
  },
});

export const {
  setUser,
  setIsLoggedIn,
  setIsLoading,
  setMessage,
  setStatus,
  setIsDataShown,
  setIsNoticeShown,
  setWarning,
  setError,
  setContacts,
  setNewAnnouncement,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
