import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrowdown } from 'icons/arrowdown.svg';
import { ReactComponent as Arrowup } from 'icons/arrowup.svg';
import css from './Select.module.scss';

export const Select = ({
  data,
  title,
  width,
  icon,
  updateGlobalState,
  initialValue,
  shift,
  pad,
  name,
  open,
  onToggleOpen,
}) => {
  const [value, setValue] = useState(initialValue || data[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const itemRefs = useRef([]);

  useEffect(() => {
    if (isDropdownOpen) {
      const selectedItemIndex = data.findIndex(item => item === value);
      if (dropdownRef.current && itemRefs.current[selectedItemIndex]) {
        dropdownRef.current.scrollTop =
          itemRefs.current[selectedItemIndex].offsetTop -
          dropdownRef.current.offsetTop;
      }
    }
  }, [isDropdownOpen, data, value]);

  useEffect(() => {
    if (data.length === 0) {
      setValue('');
    } else {
      setValue(initialValue || data[0]);
    }
  }, [data, initialValue]);

  const clickHandler = e => {
    const { value } = e.target;
    setValue(value);
    setIsDropdownOpen(false);

    if (updateGlobalState) {
      updateGlobalState(value);
    }
  };

  const dropdownOpen = () => {
    if (onToggleOpen) {
      onToggleOpen();
    }
    setIsDropdownOpen(state => !state);
  };

  return (
    <>
      <label className={css.title} style={{ width: width, margin: shift }}>
        {title}
        {icon && <span className={css.starticon}>{icon}</span>}
        <input
          className={`field ${pad}`}
          type="text"
          value={value}
          name={name}
          readOnly
        />
        <button
          className={css.btn}
          type="button"
          onClick={dropdownOpen}
          disabled={!value}
        >
          {isDropdownOpen && open ? (
            <Arrowup className={css.icon} />
          ) : (
            <Arrowdown className={css.icon} />
          )}
        </button>
        {open && isDropdownOpen && (
          <div className={css.dropdown} ref={dropdownRef}>
            <ul className={css.list}>
              {data.map((item, index) => (
                <li
                  key={index}
                  className={css.item}
                  ref={el => (itemRefs.current[index] = el)}
                >
                  <button
                    className={
                      item === value
                        ? `${css.button} ${css.active}`
                        : css.button
                    }
                    type="button"
                    value={item}
                    onClick={clickHandler}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </label>
    </>
  );
};
