import { api } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAllPosts = createAsyncThunk(
  'posts/getAllPosts',
  async (credentials, thunkAPI) => {
    try {
      const { region, city, category, page } = credentials;
      const { data } = await api.get(
        `/posts?region=${region}&city=${city}&category=${category}&page=${page}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const addNewPost = createAsyncThunk(
  'posts/addNewPost',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post('/posts', credentials, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const addNewAnswer = createAsyncThunk(
  'posts/addNewAnswer',
  async (credentials, thunkAPI) => {
    try {
      const { id, newAnswer } = credentials;
      const { data } = await api.post(`/posts/${id}/reply`, newAnswer);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const confirmParticipation = createAsyncThunk(
  'posts/confirmParticipation',
  async (credentials, thunkAPI) => {
    try {
      const { id } = credentials;
      const { data } = await api.patch(`/posts/${id}/confirm`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async (credentials, thunkAPI) => {
    try {
      const { id } = credentials;
      const { data } = await api.delete(`/posts/${id}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const searchPosts = createAsyncThunk(
  'posts/searchPosts',
  async (credentials, thunkAPI) => {
    try {
      const {
        query = '',
        region = '',
        city = '',
        category = 'forum',
      } = credentials;
      const { data } = await api.get(
        `/posts/search?region=${region}&city=${city}&category=${category}&query=${query}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
