import { api } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const adminLogin = createAsyncThunk(
  'admin/adminLogin',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post('/admin/login', credentials);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getStats = createAsyncThunk(
  'admin/getStats',
  async (credentials, thunkAPI) => {
    try {
      const { region = 'All', city = 'All', category = 'All' } = credentials;
      const { data } = await api.post(
        `/admin?region=${region}&city=${city}&category=${category}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const addAdvertise = createAsyncThunk(
  'posts/addAdvertise',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post('/admin/add', credentials, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getAdvertise = createAsyncThunk(
  'posts/getAdvertise',
  async (credentials, thunkAPI) => {
    try {
      const { region, city } = credentials;
      const { data } = await api.get(
        `/admin/advertise?region=${region}&city=${city}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
