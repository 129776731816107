const regions = [
  { region: 'Все регионы', regionName: 'All' },
  {
    region: 'Андалусия',
    regionName: 'Andalucia',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Адра',
        cityName: 'Adra',
        districts: [],
        location: [-2.9962763, 36.7346372],
      },
      {
        city: 'Альмерия',
        cityName: 'Almeria',
        districts: [],
        location: [-2.4760062, 36.833809],
      },
      {
        city: 'Баньолес',
        cityName: 'Banyoles',
        districts: [],
        location: [2.7468802, 42.116891],
      },
      {
        city: 'Бенальмодена',
        cityName: 'Benalmadena',
        location: [-4.5561386, 36.5795534],
      },
      {
        city: 'Гранада',
        cityName: 'Granada',
        districts: [],
        location: [-3.5506147, 37.154379],
      },
      {
        city: 'Гуальдаманса',
        cityName: 'Gualdalmansa',
        districts: [],
        location: [-5.0660919, 36.4390716],
      },
      {
        city: 'Эстепона',
        cityName: 'Estepona',
        location: [-4.9687833, 36.5233431],
      },
      {
        city: 'Кадис',
        cityName: 'Kadis',
        districts: [],
        location: [-6.3214091, 36.5164291],
      },
      {
        city: 'Кастель-де-Ферро',
        cityName: 'Castel-de-Ferro',
        districts: [],
        location: [-3.476997, 36.7268381],
      },
      {
        city: 'Кордоба',
        cityName: 'Cordoba',
        districts: [],
        location: [-4.7563786, 37.8799449],
      },
      {
        city: 'Мотриль',
        cityName: 'Motril',
        districts: [],
        location: [-3.476997, 36.7268381],
      },
      {
        city: 'Плайяс-де-Вера',
        cityName: 'Playas',
        districts: [],
        location: [-1.8049007, 37.2152759],
      },
      {
        city: 'Пуэрто-Реаль',
        cityName: 'Puerto-Real',
        districts: [],
        location: [-6.1769026, 36.516133],
      },
      {
        city: 'Малага',
        cityName: 'Malaga',
        districts: [
          'Весь город',
          'Эсте',
          'Каретера-де-кадис',
          'Лимонар',
          'Малагета',
          'Пало',
          'Сохо',
          'Сьюдад-Хардин',
          'Театинос',
          'Центр',
        ],
        location: [-4.3926966, 36.7160303],
      },
      {
        city: 'Марбелья',
        cityName: 'Marbella',
        districts: [],
        location: [-4.8701615, 36.5078897],
      },
      {
        city: 'Ринкон-дела-Виктория',
        cityName: 'Rincon-dela-Victoria',
        districts: [],
        location: [-4.2578714, 36.6935071],
      },
      {
        city: 'Ронда',
        cityName: 'Ronda',
        districts: [],
        location: [-5.1646457, 36.7372732],
      },
      {
        city: 'Севилья',
        cityName: 'Sevilla',
        districts: [],
        location: [-5.9952543, 37.3526201],
      },
      {
        city: 'Сан-Педро-Алькантара',
        cityName: 'San-Pedro-Alcantara',
        districts: [],
        location: [-4.8950792, 36.4091926],
      },
      {
        city: 'Торре-дель-Мар',
        cityName: 'Torre-del-Mar',
        districts: [],
        location: [-4.0930559, 36.7419131],
      },
      {
        city: 'Торремолинос',
        cityName: 'Torremolinos',
        location: [-4.5095552, 36.6263938],
      },
      {
        city: 'Уэльва',
        cityName: 'Uelva',
        districts: [],
        location: [-6.9635581, 37.2416032],
      },
      {
        city: 'Фаро',
        cityName: 'Faro',
        location: [-4.6479698, 36.507345],
      },
      {
        city: 'Фуэнхирола',
        cityName: 'Fuengirola',
        location: [-4.6911965, 36.5485695],
      },
      {
        city: 'Херес',
        cityName: 'Jerez',
        districts: [],
        location: [-6.0940836, 36.6567145],
      },
    ],
  },
  {
    region: 'Арагон',
    regionName: 'Aragon',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Сарагоса',
        cityName: 'Saragosa',
        districts: [],
        location: [-0.880291, 41.6502466],
      },
    ],
  },
  {
    region: 'Астурияс',
    regionName: 'Asturias',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Овьедо',
        cityName: 'Oviedo',
        districts: [],
        location: [-5.8898575, 43.3694795],
      },
      {
        city: 'Хихон',
        cityName: 'Gijon',
        districts: [],
        location: [-5.7096542, 43.5314211],
      },
    ],
  },
  {
    region: 'Балеарские острова',
    regionName: 'Balears',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Алькудия',
        cityName: 'Alkudia',
        districts: [],
        location: [3.117547, 39.8511468],
      },
      {
        city: 'Ибица',
        cityName: 'Ivisa',
        districts: [],
        location: [1.4090483, 38.9073264],
      },
      {
        city: 'Менорка',
        cityName: 'Menorka',
        districts: [],
        location: [3.8453638, 39.9804512],
      },
      {
        city: 'Пальма-де-Майорка',
        cityName: 'Palma',
        districts: [],
        location: [2.5983537, 39.5699236],
      },
      {
        city: 'Магалуф',
        cityName: 'Magaluf',
        districts: [],
        location: [2.5311583, 39.5021861],
      },
      {
        city: 'Сан-Антонио-Абад',
        cityName: 'San-Antonio-Abad',
        districts: [],
        location: [1.3080248, 38.9676],
      },
      {
        city: 'Сольер',
        cityName: 'Solier',
        districts: [],
        location: [2.7153058, 39.7671412],
      },
    ],
  },
  {
    region: 'Валенсия',
    regionName: 'Valencia',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Аликанте',
        cityName: 'Alicante',
        districts: [
          'Весь город',
          'Беналуа',
          'Вистаэрмоса',
          'Плая де Сан-Хуан',
          'Центр',
          'Сан Блас-Палау',
          'Хуан XXII',
          'Кампоамор',
        ],
        location: [-0.4929183, 38.3462107],
      },
      {
        city: 'Альбир',
        cityName: 'Albir',
        districts: [],
        location: [-0.1580906, 38.5547473],
      },
      {
        city: 'Алькой',
        cityName: 'Alcoy',
        districts: [],
        location: [-0.5563555, 38.7005563],
      },
      {
        city: 'Альтеа',
        cityName: 'Altea',
        districts: [],
        location: [-0.0608657, 38.5979972],
      },
      {
        city: 'Альфас-дель-Пи',
        cityName: 'Alfas-del-Pi',
        districts: [],
        location: [-0.2015282, 38.5900635],
      },
      {
        city: 'Бенидорм',
        cityName: 'Benidorm',
        districts: [],
        location: [-0.1711952, 38.5374003],
      },
      {
        city: 'Бениса',
        cityName: 'Benisa',
        districts: [],
        location: [0.0430161, 38.7116986],
      },
      {
        city: 'Бурриана',
        cityName: 'Burianna',
        districts: [],
        location: [-0.0840781, 39.8840533],
      },
      {
        city: 'Валенсия',
        cityName: 'Valencia',
        districts: [
          'Весь город',
          'Альхирос',
          'Беникалап',
          'Бенимаклет',
          'Каминс-аль-Грау',
          'Кампанар',
          'Куатре-Карререс',
          'Оливерета',
          'Эшампле',
          'Ла-Сайдия',
          'Патраш',
          'Пла-дель-Реаль',
          'Поблатс-Маритимс',
          'Поблес-де-л’Оэст',
          'Поблес-дель-Норд',
          'Поблес-дель-Суд',
          'Расканья',
          'Сьютат-Велья',
          'Хесус',
          'Экстрамурс',
        ],
        location: [-0.3773596, 39.4548342],
      },
      {
        city: 'Вильярреаль',
        cityName: 'Villareal',
        districts: [],
        location: [-0.0955472, 39.9290667],
      },
      {
        city: 'Вильяхойоса',
        cityName: 'Vila Joyosa',
        districts: [],
        location: [-0.2994803, 38.5104578],
      },
      {
        city: 'Гандия',
        cityName: 'Gandia',
        districts: [],
        location: [-0.1916817, 39.0229947],
      },
      {
        city: 'Гуардамар-дель-Сегура',
        cityName: 'Guardamar-del-Segura',
        districts: [],
        location: [-0.6928019, 38.0893587],
      },
      {
        city: 'Дения',
        cityName: 'Denia',
        districts: [],
        location: [0.1075792, 38.8411111],
      },
      {
        city: 'Эльче',
        cityName: 'Elche',
        districts: [],
        location: [-0.7676231, 38.2665031],
      },
      {
        city: 'Кальп',
        cityName: 'Calp',
        districts: [],
        location: [-0.1075406, 38.6515027],
      },
      {
        city: 'Кампельо',
        cityName: 'Campello',
        districts: [],
        location: [-0.436308, 38.4271809],
      },
      {
        city: 'Косентайна',
        cityName: 'Cocentaina',
        districts: [],
        location: [-0.4792364, 38.7438353],
      },
      {
        city: 'Кастельон-де-ла-Плана',
        cityName: 'Castello-de-la-Plana',
        districts: [],
        location: [-0.0449306, 39.9794602],
      },
      {
        city: 'Ланусия',
        cityName: 'Lanucia',
        districts: [],
        location: [-0.136201, 38.6138999],
      },
      {
        city: 'Ламата',
        cityName: 'La mata',
        districts: [],
        location: [-0.6752819, 38.0217673],
      },
      {
        city: 'Ла Сения',
        cityName: 'La senia',
        districts: [],
        location: [-0.7677277, 37.9254571],
      },
      {
        city: 'Монкофар',
        cityName: 'Monkofar',
        districts: [],
        location: [-0.1460631, 39.8059422],
      },
      {
        city: 'Морайра',
        cityName: 'Moraira',
        districts: [],
        location: [0.1239166, 38.6880576],
      },
      {
        city: 'Олива',
        cityName: 'Oliva',
        districts: [],
        location: [-0.1498855, 38.9153889],
      },
      {
        city: 'Полоп',
        cityName: 'Polop',
        districts: [],
        location: [-0.1353349, 38.6224103],
      },
      {
        city: 'Пунта Прима',
        cityName: 'Punta Prima',
        districts: [],
        location: [-0.7302148, 37.945012],
      },
      {
        city: 'Рекена',
        cityName: 'Rekena',
        districts: [],
        location: [-1.13938, 39.4885426],
      },
      {
        city: 'Сагунто',
        cityName: 'Sagunto',
        districts: [],
        location: [-0.2650055, 39.6661505],
      },
      {
        city: 'Санта-Пола',
        cityName: 'Santa-Pola',
        districts: [],
        location: [-0.611004, 38.1882627],
      },
      {
        city: 'Суэка',
        cityName: 'Sueca',
        districts: [],
        location: [-0.2972, 39.1835258],
      },
      {
        city: 'Торревьеха',
        cityName: 'Torrevieja',
        districts: [],
        location: [-0.7172524, 37.9948715],
      },
      {
        city: 'Хавеа',
        cityName: 'Xabia',
        districts: [],
        location: [0.1415125, 38.7890983],
      },
      {
        city: 'Финестрат',
        cityName: 'Fenistrat',
        districts: [],
        location: [-0.2215496, 38.5650851],
      },
      {
        city: 'Шатива',
        cityName: 'Xativa',
        districts: [],
        location: [-0.5397509, 38.9889703],
      },
    ],
  },
  {
    region: 'Галисия',
    regionName: 'Galicia',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Ла-Корунья',
        cityName: 'Coruna',
        districts: [],
        location: [-8.4267106, 43.3716382],
      },
      {
        city: 'Сантьяго-де-Компостела',
        cityName: 'Santiago',
        districts: [],
        location: [-8.5859693, 42.8801976],
      },
    ],
  },
  {
    region: 'Канарские острова',
    regionName: 'Canarians',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Гран-Канария',
        cityName: 'Gran-Canaria',
        districts: [],
        location: [-15.6337109, 27.8888622],
      },
      {
        city: 'Лансароте',
        cityName: 'Lansarote',
        districts: [],
        location: [-13.5791702, 29.0597884],
      },
      {
        city: 'Санта-Крус-Де-Тенерифе',
        cityName: 'Santa-Cruz-de-Tenerife',
        districts: [],
        location: [-16.2846482, 28.4420348],
      },
      {
        city: 'Фуэртевентура',
        cityName: 'Fuerteventura',
        districts: [],
        location: [-14.0448135, 28.2208888],
      },
    ],
  },
  {
    region: 'Кантабрия',
    regionName: 'Cantabria',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Сантадер',
        cityName: 'Santader',
        districts: [],
        location: [-3.7872807, 43.4343987],
      },
    ],
  },
  {
    region: 'Кастилья-Ла-Манча',
    regionName: 'Castilla-La-Mancha',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Гваладахара',
        cityName: 'Guadalajara',
        districts: [],
        location: [-3.1196075, 40.6022048],
      },
      {
        city: 'Куэнка',
        cityName: 'Cuenca',
        districts: [],
        location: [-2.0842087, 40.0469733],
      },
      {
        city: 'Толедо',
        cityName: 'Toledo',
        districts: [],
        location: [-4.0281934, 39.8566802],
      },
    ],
  },
  {
    region: 'Кастилья и Леон',
    regionName: 'Castilla-y-Leon',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Авила',
        cityName: 'Avila',
        districts: [],
        location: [-4.697399, 40.65858],
      },
      {
        city: 'Бургос',
        cityName: 'Burgos',
        districts: [],
        location: [-3.7129145, 42.3457967],
      },
      {
        city: 'Вальядолид',
        cityName: 'Valladolid',
        districts: [],
        location: [-4.7767856, 41.5179087],
      },
      {
        city: 'Леон',
        cityName: 'Leon',
        districts: [],
        location: [-5.6553319, 42.6035792],
      },
    ],
  },
  {
    region: 'Каталония',
    regionName: 'Catalonia',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Бадалона',
        cityName: 'Badalona',
        districts: [],
        location: [2.2200621, 41.4585023],
      },
      {
        city: 'Барселона',
        cityName: 'Barcelona',
        districts: [
          'Весь город',
          'Барселонета',
          'Вилья Олимпика',
          'Грасия',
          'Эль Борн',
          'Эшампле',
          'Лес-Кортс',
          'Монджуик',
          'Орта Гинардо',
          'Петральбес',
          'Побленоу',
          'Поблесек',
          'Раваль',
          'Сантс',
          'Сант Андреу',
          'Сант Марти',
          'Саррия Сант Жервази',
          'Сьюдад Велья',
        ],
        location: [2.0577882, 41.3926386],
      },
      {
        city: 'Вильянуэва',
        cityName: 'Vilanova',
        districts: [],
        location: [1.7192982, 41.2321301],
      },
      {
        city: 'Жирона',
        cityName: 'Girona',
        districts: [],
        location: [2.7407282, 41.9802876],
      },
      {
        city: 'Эмпуриабрава',
        cityName: 'Empuriabrava',
        districts: [],
        location: [3.0990616, 42.2482143],
      },
      {
        city: 'Кадакес',
        cityName: 'Cadaques',
        districts: [],
        location: [3.3035187, 42.2753395],
      },
      {
        city: 'Калафель',
        cityName: 'Calafel',
        districts: [],
        location: [1.5534961, 41.1952001],
      },
      {
        city: 'Камбрильс',
        cityName: 'Cambrills',
        districts: [],
        location: [1.0601401, 41.0574957],
      },
      {
        city: 'Кастельдефельс',
        cityName: 'Castelldefels',
        districts: [],
        location: [1.938403, 41.2836669],
      },
      {
        city: 'Лерида',
        cityName: 'Lerida',
        districts: [],
        location: [0.5419942, 41.618316],
      },
      {
        city: 'Льорет-де-Мар',
        cityName: 'Loret-de-mar',
        districts: [],
        location: [2.8003531, 41.7026697],
      },
      {
        city: 'Майями Плайя',
        cityName: 'Miami-Playa',
        districts: [],
        location: [0.9335942, 41.0061136],
      },
      {
        city: 'Манреса',
        cityName: 'Manresa',
        districts: [],
        location: [1.7706642, 41.7109242],
      },
      {
        city: 'Матаро',
        cityName: 'Mataro',
        districts: [],
        location: [2.4317595, 41.5445305],
      },
      {
        city: 'Паламос',
        cityName: 'Palamos',
        districts: [],
        location: [3.1084557, 41.8523543],
      },
      {
        city: 'Палафольс',
        cityName: 'Palafols',
        districts: [],
        location: [2.7417399, 41.6698961],
      },
      {
        city: 'Реус',
        cityName: 'Reus',
        districts: [],
        location: [1.0702544, 41.1435865],
      },
      {
        city: 'Сабадель',
        cityName: 'Sabadel',
        districts: [],
        location: [2.0246554, 41.5496455],
      },
      {
        city: 'Салоу',
        cityName: 'Salou',
        districts: [],
        location: [1.131222, 41.0681519],
      },
      {
        city: 'Сиджес',
        cityName: 'Sitges',
        districts: [],
        location: [1.7974023, 41.2388824],
      },
      {
        city: 'Таррагона',
        cityName: 'Tarragona',
        districts: [],
        location: [1.2582269, 41.1162105],
      },
      {
        city: 'Тоса-де-Мар',
        cityName: 'Tossa-de-mar',
        districts: [],
        location: [3.1084557, 41.8523543],
      },
      {
        city: 'Фигерас',
        cityName: 'Figeras',
        districts: [],
        location: [2.9642127, 42.2546995],
      },
    ],
  },
  {
    region: 'Мадрид',
    regionName: 'Madrid',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Авила',
        cityName: 'Avila',
        districts: [],
        location: [-4.6318379, 40.6266623],
      },
      {
        city: 'Алькокорн',
        cityName: 'Alcorcon',
        districts: [],
        location: [-3.8312649, 40.3332377],
      },
      {
        city: 'Мадрид',
        cityName: 'Madrid',
        districts: [
          'Весь город',
          'Арганзуэла',
          'Барахас',
          'Викальваро',
          'Вилья-Вальекас',
          'Карабанчель',
          'Латина',
          'Ретиро',
          'Саламанка',
          'Тетуан',
          'Центр',
          'Чамартин',
        ],
        location: [-3.7664199, 40.4181943],
      },
      {
        city: 'Мостолес',
        cityName: 'Mostoles',
        districts: [],
        location: [-3.8561365, 40.3131511],
      },
      {
        city: 'Толедо',
        cityName: 'Toledo',
        districts: [],
        location: [-4.0281934, 39.8566802],
      },
      {
        city: 'Хетафе',
        cityName: 'Getafe',
        districts: [],
        location: [-3.7393865, 40.3017873],
      },
    ],
  },
  {
    region: 'Мурсия',
    regionName: 'Murcia',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Картахена',
        cityName: 'Cartagena',
        districts: [],
        location: [-1.0907486, 37.6149535],
      },
      {
        city: 'Лорка',
        cityName: 'Lorka',
        districts: [],
        location: [-1.6977426, 37.6677579],
      },
      {
        city: 'Мурсия',
        cityName: 'Murcia',
        districts: [],
        location: [-1.1570511, 37.9776652],
      },
      {
        city: 'Сан-Хавьер',
        cityName: 'San-Javier',
        districts: [],
        location: [-0.905825, 37.8041717],
      },
    ],
  },
  {
    region: 'Наварра',
    regionName: 'Navarra',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Памплона',
        cityName: 'Pamplona',
        districts: [],
        location: [-1.6472559, 42.8117063],
      },
    ],
  },
  {
    region: 'Cтрана Басков',
    regionName: 'Euskadi',
    cities: [
      { city: 'Все города', cityName: 'All' },
      {
        city: 'Бильбао',
        cityName: 'Bilbao',
        districts: [],
        location: [-3.0269731, 43.2577171],
      },
      {
        city: 'Сан-Себастьян',
        cityName: 'San-Sebastian',
        districts: [],
        location: [-1.9177543, 43.2978968],
      },
    ],
  },
];

export const regionsList = regions.map(item => item.region);

export const regionSelect = name => {
  if (!name) return '';
  const selectedRegion = regions.find(item => item.region === name);
  return selectedRegion.regionName;
};

export const citiesSelect = region => {
  if (!region) return '';

  if (region === 'All') {
    return [];
  }
  const selectedRegion = regions.find(item => item.regionName === region);
  const citiesList = selectedRegion.cities.map(item => item.city);
  return citiesList;
};

export const showCities = region => {
  if (!region) return '';

  if (region === 'All') {
    return [];
  }
  const selectedRegion = regions.find(item => item.region === region);
  const list = selectedRegion.map(item => item.cities);
  const citiesList = list.map(item => item.city);
  return citiesList;
};

export const citySelect = value => {
  if (!value) return '';
  const region = regions.find(
    item => item.cities && item.cities.some(item => item.city === value)
  );

  if (!region) {
    return '';
  }
  const selectedCity = region.cities.find(item => item.city === value);
  return selectedCity.cityName;
};

export const replaceRegion = value => {
  if (!value) return '';

  const replacedRegion = regions.find(item => item.regionName === value);
  if (!replacedRegion) return '';
  return replacedRegion.region;
};

export const replaceCity = value => {
  if (!value) return '';

  const region = regions.find(
    item => item.cities && item.cities.some(city => city.cityName === value)
  );

  if (!region) {
    return '';
  }

  const replacedCity = region.cities.find(item => item.cityName === value);
  return replacedCity.city;
};

export const showDistricts = city => {
  if (!city) return [];

  const region = regions.find(
    item => item.cities && item.cities.some(item => item.city === city)
  );

  if (!region) {
    return []; // Returns empty array if the city is not found in any region
  }

  const selectedCity = region.cities.find(item => item.city === city);

  if (!selectedCity) {
    return []; // Returns empty array if the city is not found in the selected region
  }

  return selectedCity.districts || [];
};

export const showDistrictsList = city => {
  if (!city) return [];

  const region = regions.find(
    item => item.cities && item.cities.some(item => item.cityName === city)
  );

  if (!region) {
    return []; // Returns empty array if the city is not found in any region
  }

  const selectedCity = region.cities.find(item => item.cityName === city);

  if (!selectedCity) {
    return []; // Returns empty array if the city is not found in the selected region
  }

  return selectedCity.districts || [];
};

export const showLocation = (region = 'All', city = 'All') => {
  if (!region || region === 'All') {
    return [35.2433, 38.9637];
  }

  const selectedRegion = regions.find(item => item.regionName === region);

  if (!selectedRegion) return [];

  if (city === 'All') {
    const city = selectedRegion.cities.find(
      item => item.cityName === selectedRegion.regionName
    );
    return city?.location || [];
  }
  const selectedCity = selectedRegion.cities.find(
    item => item.cityName === city
  );
  return selectedCity?.location || [];
};
