import { createSlice } from '@reduxjs/toolkit';
import {
  getAllPosts,
  addNewAnswer,
  addNewPost,
  confirmParticipation,
  deletePost,
  searchPosts,
} from './operations';

const initialState = {
  forum: [],
  trips: [],
  events: [],
  meetings: [],
  totalPages: null,
  isLoading: false,
  message: '',
  status: '',
  isAddFormShown: false,
  error: false,
};

const postsSlice = createSlice({
  name: 'posts',
  initialState: initialState,
  reducers: {
    setIsAddFormShown: (state, action) => {
      state.isAddFormShown = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPostsMessage: (state, action) => {
      state.message = action.payload;
    },
    setPostsStatus: (state, action) => {
      state.status = action.payload;
    },
    setPostsError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addNewPost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAddFormShown = false;
        state.message = 'Успешно создано!';
        state.status = 'success';
      })
      .addCase(addNewPost.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(getAllPosts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPosts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (state.totalPages > 1) {
          state[payload.category] = [
            ...state[payload.category],
            ...payload.posts,
          ];
        } else {
          state[payload.category] = [...payload.posts];
        }
        state.totalPages = payload.totalPages;
      })
      .addCase(getAllPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.status = 'error';
      })
      .addCase(addNewAnswer.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewAnswer.fulfilled, (state, action) => {
        state.isLoading = false;
        const postIndex = state.forum.findIndex(
          post => post._id === action.payload._id
        );
        if (postIndex > -1) {
          const newAnswer = action.payload.newAnswer;
          state.forum[postIndex].answers = [
            newAnswer,
            ...state.forum[postIndex].answers,
          ];
        }
      })
      .addCase(addNewAnswer.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(confirmParticipation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(confirmParticipation.fulfilled, (state, action) => {
        state.isLoading = false;
        const postIndex = state.events.findIndex(
          post => post._id === action.payload._id
        );
        if (postIndex !== -1) {
          state.events[postIndex].participants.push(action.payload.user);
        }
      })
      .addCase(confirmParticipation.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(deletePost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = 'Успешно удалено';
        state.status = 'success';
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
      })
      .addCase(searchPosts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state[action.payload.category] = action.payload.posts;
      })
      .addCase(searchPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      });
  },
});

export const {
  setIsAddFormShown,
  setTotalPages,
  setPostsMessage,
  setPostsStatus,
  setPostsError,
} = postsSlice.actions;
export const postsReducer = postsSlice.reducer;
