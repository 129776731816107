export const isoDateFormatter = (isoDate, onlyToday = false) => {
  const currentTime = new Date();
  const date = new Date(isoDate);

  if (isNaN(date.getTime())) {
    return 'недавнего времени';
  }

  const differenceInSeconds = Math.abs((currentTime - date) / 1000);

  if (differenceInSeconds < 60) {
    return 'сейчас';
  }

  const originalHour = date.getHours();
  const originalMinute = date.getMinutes();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  date.setHours(0, 0, 0, 0);

  if (date.getTime() === today.getTime()) {
    if (onlyToday) {
      return 'сегодня';
    }
    return `сегодня в ${originalHour}:${('0' + originalMinute).slice(-2)}`;
  } else if (date.getTime() === yesterday.getTime()) {
    return 'вчера';
  } else {
    const day = date.getDate();
    const year = date.getFullYear();

    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];
    const month = months[date.getMonth()];

    return `${day} ${month} ${year}`;
  }
};
