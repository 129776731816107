import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Header } from '../Header/Header';
import { MobileNavbar } from 'components/MobileNavbar/MobileNavbar';
import { Container } from 'components/Container/Container';
import { Loader } from '../Loader/Loader';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { Popup } from 'components/Popup/Popup';
import { Search } from 'components/Search/Search';
import { Selectbar } from 'components/Selectbar/Selectbar';
import { Warning } from 'components/Warning/Warning';
import { ReactComponent as Up } from 'icons/up.svg';
import { ReactComponent as Spain } from 'icons/spain.svg';
import { GrInstagram } from 'react-icons/gr';
import { BsFacebook } from 'react-icons/bs';
import { throttle } from 'throttle-debounce';
import { getAdvertise } from 'redux/slices/admin/operations';
import { mediaSizes } from 'constants/constants';
import css from './SharedLayout.module.scss';

export const SharedLayout = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [isDesktop, setIsDesktop] = useState(true);

  const { message, status } = useSelector(state => state.auth);
  const { isAddFormShown } = useSelector(state => state.posts);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const { region, selectedCity: city } = useSelector(state => state.location);
  const { advertise } = useSelector(state => state.admin);

  const location = useLocation();
  const dispatch = useDispatch();

  const isMatchingRoute =
    /^\/$|^\/announcements\/search$|\/[^/]+\/[^/]+\/(?!posts\/[^/]+$)[^/]+\/announcements$|\/[^/]+\/[^/]+\/posts\/[^/]+$/.test(
      location.pathname
    );

  const isMatchingSearch =
    /^\/$|^\/announcements\/search$|\/[^/]+\/[^/]+\/(?!posts\/[^/]+$)[^/]+\/announcements$/.test(
      location.pathname
    );

  const isPostsTripsRoute = location.pathname.includes('posts/trips');

  const isPostsAndForumInPath = location.pathname.includes('posts/forum');

  const throttledCheckVisibility = throttle(200, () => {
    if (window.pageYOffset > window.innerHeight * 0.9) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', throttledCheckVisibility);
    return () => {
      window.removeEventListener('scroll', throttledCheckVisibility);
    };
  }, [throttledCheckVisibility]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // useEffect(() => {
  //   dispatch(getAdvertise({ region, city }));
  // }, [region, city, dispatch]);

  // useEffect(() => {
  //   if (advertise?.length > 1) {
  //     setTimeout(() => {
  //       if (count < advertise.length) {
  //         setCount(state => state + 1);
  //       } else {
  //         setCount(0);
  //       }
  //     }, 120000);
  //   }
  // }, [advertise, count]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseInt(mediaSizes.desktop)) {
        setIsDesktop(false);
      } else {
        setIsDesktop(true);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Popup message={message} status={status} />
      <Header />
      <section className={css.hero}>
        <Spain />
        <div className={css.container}>
          <h1 className={css.heading}>Здесь все свои</h1>
          <p className={css.text}>наши для наших в испании</p>
          <p className={css.board}>доска объявлений</p>
        </div>
      </section>
      {isMatchingRoute && <Selectbar />}
      {isMatchingSearch && (
        <Search route={'announcements'} placeholder={'поиск по сайту'} />
      )}
      {isPostsAndForumInPath && !isAddFormShown && (
        <Search route={'posts'} placeholder={'поиск по темам'} />
      )}
      {isPostsTripsRoute && !isAddFormShown && (
        <Search
          route={'posts'}
          placeholder={'поиск направления'}
          trips={true}
        />
      )}
      <main className={css.wrapper}>
        <Container>
          {/* <div className={css.advertise}>
            <p className={css.advertiseText}>Здесь должна быть ваша реклама</p>
          </div> */}
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Container>
        {isVisible && (
          <button type="button" className={css.btn} onClick={scrollToTop}>
            <Up className={css.icon} />
          </button>
        )}
        {isLoggedIn && <MobileNavbar />}
        <Warning />
      </main>
      <footer
        className={css.foot}
        style={{ marginBottom: isLoggedIn && !isDesktop ? '60px' : '0' }}
      >
        <p className={css.copy}>Vse Svoi 2023 &copy;</p>
        <p style={{ fontSize: '14px', color: '#fff' }}>Все права защищены.</p>
        <p style={{ fontSize: '12px', color: '#fff', marginTop: '5px' }}>
          Электроннная почта: admin_vsesvoi@proton.me
        </p>
        <div className={css.linksWrapper}>
          <a
            className={css.link}
            href="https://www.instagram.com/vsesvoi2024/?igsh=cWlyOTZmYzF1N3J3"
            target="blank"
          >
            <GrInstagram />
          </a>
          <a
            className={css.link}
            href="https://www.facebook.com/profile.php?id=61553589759858"
            target="blank"
          >
            <BsFacebook />
          </a>
        </div>
      </footer>
    </>
  );
};
