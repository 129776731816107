import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, lazy } from 'react';
import { setMessage, setStatus } from 'redux/slices/auth/authSlice';
import ReactGA from 'react-ga4';
import {
  getNewMessage,
  getMessageSent,
} from 'redux/slices/announcements/annoncementSlice';
import { setAuthHeader } from 'api';
import { getUnreadMessages } from 'redux/slices/announcements/operations';
import { setNewAnnouncement } from 'redux/slices/auth/authSlice';
import { setError } from 'redux/slices/auth/authSlice';
import { setAnnouncementsError } from 'redux/slices/announcements/annoncementSlice';
import {
  setAnnouncementsMessage,
  setAnnouncementsStatus,
} from 'redux/slices/announcements/annoncementSlice';
import { setPostsError } from 'redux/slices/posts/postsSlice';
import { clearNewAnnouncement } from 'redux/slices/announcements/annoncementSlice';
import { logout } from 'redux/slices/auth/operations';
import { SharedLayout } from 'components/SharedLayout/SharedLayout';
import { Loader } from 'components/Loader/Loader';
import { PrivateRoute } from 'components/privateRoute';
import { RestrictedRoute } from 'components/restrictedRoute';
import { AdminRoute } from 'components/adminRoute';
import { ProfileLayout } from 'components/ProfileLayout/ProfileLayout';
import { initiateSocket, disconnectSocket } from '../../socket';
import css from './App.module.scss';

const NewAnnouncement = lazy(() =>
  import('pages/NewAnnouncement/NewAnnouncement')
);
const Login = lazy(() => import('pages/Login'));
const Home = lazy(() => import('pages/Home'));
const UserAnnouncements = lazy(() => import('pages/UserAnnouncements'));
const UserContacts = lazy(() => import('pages/UserContacts'));
const Register = lazy(() => import('pages/Register'));
const RestorePassword = lazy(() => import('pages/RestorePassword'));
const Announcements = lazy(() => import('pages/Announcements/Announcements'));
const AnnouncementDetails = lazy(() => import('pages/AnnouncementDetails'));
const Posts = lazy(() => import('pages/Posts'));
const Verify = lazy(() => import('pages/Verify/Verify.jsx'));
const NotFound = lazy(() => import('pages/NotFound'));
const EditAnnouncement = lazy(() => import('pages/EditAnnouncement'));
const OtherAnnouncements = lazy(() => import('pages/OtherAnnouncements'));
const UserMessages = lazy(() => import('pages/UserMessages'));
const UserConversations = lazy(() => import('pages/UserConversations'));
const Senders = lazy(() => import('pages/Senders'));
const UserResponses = lazy(() => import('pages/UserResponses'));
const Search = lazy(() => import('pages/Search'));
const PostSearch = lazy(() => import('pages/PostSearch'));
const AnnouncementMessages = lazy(() => import('pages/AnnouncementMessages'));
const AdminLogin = lazy(() => import('pages/AdminLogin'));
const AdminPage = lazy(() => import('pages/AdminPage'));

export const App = () => {
  const { isLoading, isLoggedIn, message, status, token, error } = useSelector(
    state => state.auth
  );
  const announcementMessage = useSelector(state => state.announcements.message);
  const announcementStatus = useSelector(state => state.announcements.status);
  const { newAnnouncement } = useSelector(state => state.announcements);
  const postLoading = useSelector(state => state.posts.isLoading);
  const announcementLoading = useSelector(
    state => state.announcements.isLoading
  );
  const announcementError = useSelector(state => state.announcements.error);
  const postError = useSelector(state => state.posts.error);
  const { _id = '' } = useSelector(state => state?.auth?.user);
  const { isNewMessage } = useSelector(state => state.announcements);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const googleKey = process.env.REACT_APP_GA_MEASUREMENT_ID;

  useEffect(() => {
    ReactGA.initialize(googleKey);
  });

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  useEffect(() => {
    const body = document.body;

    if (isLoading || postLoading || announcementLoading) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'scroll';
    }
  }, [isLoading, postLoading, announcementLoading]);

  useEffect(() => {
    if (error || announcementError || postError) {
      dispatch(logout());
      dispatch(setError(false));
      dispatch(setAnnouncementsError(false));
      dispatch(setPostsError(false));
      setTimeout(() => {
        navigate('/login');
      }, 100);
    }
  }, [dispatch, navigate, error, announcementError, postError]);

  useEffect(() => {
    if (token) {
      setAuthHeader(token);
    }
  }, [token]);

  useEffect(() => {
    if (isNewMessage) {
      dispatch(getUnreadMessages());
    }
  }, [isNewMessage, dispatch]);

  useEffect(() => {
    if (isLoggedIn && token) {
      dispatch(getUnreadMessages());
    }
  }, [dispatch, isLoggedIn, token]);

  useEffect(() => {
    const resetMessage = () => {
      setTimeout(() => {
        dispatch(setMessage(''));
        dispatch(setStatus(''));
        dispatch(setAnnouncementsMessage(''));
        dispatch(setAnnouncementsStatus(''));
      }, 3500);
    };

    resetMessage();
  }, [dispatch, message, status, announcementMessage, announcementStatus]);

  useEffect(() => {
    let localSocket;
    if (isLoggedIn && _id) {
      localSocket = initiateSocket(token);

      localSocket.on('connect', () => {
        localSocket.emit('user_connected', _id);
      });

      localSocket.on('message', conversation => {
        dispatch(getNewMessage(conversation));
        setTimeout(() => {
          dispatch(getUnreadMessages());
        }, 100);

        console.log('new message has come', conversation);
      });

      localSocket.on('sent', conversation => {
        dispatch(getMessageSent(conversation));
        console.log('sent event is coming', conversation);
      });

      localSocket.on('disconnect', () => {
        console.log('Disconnected from the server');
      });

      return () => {
        localSocket.off('connect');
        localSocket.off('message');
        localSocket.off('sent');
        localSocket.off('disconnect');
        disconnectSocket();
      };
    }
  }, [isLoggedIn, dispatch, _id, token]);

  useEffect(() => {
    if (newAnnouncement) {
      dispatch(setNewAnnouncement(newAnnouncement));
      dispatch(clearNewAnnouncement(''));
    }
  }, [newAnnouncement, dispatch]);

  return (
    <>
      <div className={css.container}>
        {isLoading && <Loader />}
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<Home />} />
            <Route path="/announcements/search" element={<Search />} />
            <Route
              path="/login"
              element={<RestrictedRoute redirectTo="/" component={<Login />} />}
            />
            <Route path="/admin/admin/admin/login" element={<AdminLogin />} />
            <Route
              path="/register"
              element={
                <RestrictedRoute redirectTo="/" component={<Register />} />
              }
            />
            <Route
              path="/admin/admin/admin"
              element={<AdminRoute redirectTo="/" component={<AdminPage />} />}
            />
            <Route path="/users/verify/:token" element={<Verify />} />
            <Route
              path="/users/restore/:restoreKey"
              element={<RestorePassword />}
            />
            <Route path="/:region/:city/posts/:category" element={<Posts />} />
            <Route path="/posts/:category/search" element={<PostSearch />} />

            <Route
              path="/:region/:city/:category/announcements"
              element={<Announcements />}
            />
            <Route
              path="/:region/:city/:category/announcements/:id/others"
              element={<OtherAnnouncements />}
            />
            <Route
              path="/:region/:city/:category/announcements/:id"
              element={<AnnouncementDetails />}
            />
            <Route path="/users/:id/profile/" element={<ProfileLayout />}>
              <Route
                path="responses"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<UserResponses />}
                  />
                }
              />
              <Route
                path="announcements/:type"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<UserAnnouncements />}
                  />
                }
              />
              <Route
                path="announcements/active/:announcementId/edit"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<EditAnnouncement />}
                  />
                }
              />
              <Route
                path="announcements/new"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<NewAnnouncement />}
                  />
                }
              />
              <Route
                path="conversations/:conversationType"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<UserConversations />}
                  />
                }
              />
              <Route
                path="conversations/:conversationType/senders/:announcementId"
                element={
                  <PrivateRoute redirectTo="/login" component={<Senders />} />
                }
              />
              <Route
                path="conversations/:type/messages/:announcementId"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<AnnouncementMessages />}
                  />
                }
              />
              <Route
                path="conversations/:type/:conversationId/messages"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<UserMessages />}
                  />
                }
              />
              <Route
                path="settings/contacts"
                element={
                  <PrivateRoute
                    redirectTo="/login"
                    component={<UserContacts />}
                  />
                }
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};
