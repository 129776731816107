import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Enter } from '../../icons/enter.svg';
import { ReactComponent as Exit } from '../../icons/exit.svg';
import { logout } from 'redux/slices/auth/operations';
import { setIsDataShown } from 'redux/slices/auth/authSlice';
import { mediaSizes } from 'constants/constants';
import defaultAvatar from '../../icons/defaultAvatar.svg';
import css from './UserInfo.module.scss';

export const UserInfo = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { _id, avatarURL } = useSelector(state => state.auth.user);
  const { unreadMessages } = useSelector(state => state.announcements);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseInt(mediaSizes.desktop)) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openModalHandler = () => {
    setIsModalShown(state => !state);
  };

  const openDataHandler = () => {
    dispatch(setIsDataShown(true));

    const scrollToTop = () => {
      const currentScrollPosition = window.scrollY;

      window.scrollTo({
        top: currentScrollPosition + 200,
        behavior: 'smooth',
      });
    };

    scrollToTop();
  };

  const logoutHandler = () => {
    dispatch(logout({ _id }));
    setIsModalShown(state => !state);
    navigate('/');
  };

  return (
    <div className={css.wrapper}>
      {isLoggedIn ? (
        <>
          {!isMobileScreen && (
            <Link
              to={`users/${_id}/profile/announcements/active`}
              title="ваш профиль"
              onClick={openDataHandler}
              className={css.avatar}
            >
              <img
                src={avatarURL || defaultAvatar}
                alt="avatar"
                className={css.avatar}
              />
              {unreadMessages.sell + unreadMessages.buy > 0 && (
                <span className={css.messages}>
                  {unreadMessages.sell + unreadMessages.buy}
                </span>
              )}
            </Link>
          )}
          <button
            className={css.link}
            onClick={openModalHandler}
            type="button"
            title="выйти"
          >
            <Exit className={css.icon} />
            выйти
          </button>
          <div
            className={
              isModalShown ? `${css.modal}` : `${css.hidden} ${css.modal}`
            }
          >
            <p className={css.text}>Вы уверенны, что хотите выйти?</p>
            <button
              style={{ width: '20%' }}
              className={`btn local`}
              type="button"
              onClick={logoutHandler}
            >
              да
            </button>
            <button
              style={{ width: '20%' }}
              className={`btn local`}
              type="button"
              onClick={openModalHandler}
            >
              нет
            </button>
          </div>
        </>
      ) : (
        <Link to="/login" className={css.link} title="войти">
          <Enter className={css.icon} />
          войти
        </Link>
      )}
    </div>
  );
};
