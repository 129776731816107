import { configureStore } from '@reduxjs/toolkit';
import { locationReducer } from './slices/location/locationSlice';
import { authReducer } from './slices/auth/authSlice';
import { postsReducer } from './slices/posts/postsSlice';
import { announcementReducer } from './slices/announcements/annoncementSlice';
import { adminReducer } from './slices/admin/adminSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const regionConfig = {
  key: 'location',
  storage,
};

const authConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'isLoggedIn', 'user'],
};

const announcementConfig = {
  key: 'announcements',
  storage,
  whitelist: ['selected'],
};

const adminConfig = {
  key: 'admin',
  storage,
  whitelist: ['token'],
};

export const store = configureStore({
  reducer: {
    location: persistReducer(regionConfig, locationReducer),
    auth: persistReducer(authConfig, authReducer),
    posts: postsReducer,
    announcements: persistReducer(announcementConfig, announcementReducer),
    admin: persistReducer(adminConfig, adminReducer),
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
