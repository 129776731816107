import { useLocalStorage } from 'hooks/useLocalStorage';
import { CiSearch } from 'react-icons/ci';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import css from './Search.module.scss';
import { useSelector } from 'react-redux';

export const Search = ({ route, placeholder, trips = false }) => {
  const [value, setValue] = useLocalStorage('search', '');

  const { region, selectedCity: city } = useSelector(state => state.location);

  const navigate = useNavigate();

  const typingHandler = e => {
    setValue(e.target.value.toLowerCase());
  };

  const submitHandler = e => {
    e.preventDefault();
    navigate(
      route === 'posts'
        ? `/${route}/${
            trips ? 'trips/' : 'forum/'
          }search?region=${region}&city=${city}&category=${
            trips ? 'trips' : 'forum'
          }&query=${value}`
        : `/${route}/search?region=${region}&city=${city}&query=${value}`
    );
    setValue('');
    window.scrollTo(0, 0);
  };

  return (
    <form className={css.search} onSubmit={submitHandler}>
      <label className={css.title}>
        <CiSearch className={css.icon} />
        <input
          type="text"
          className={css.field}
          onChange={typingHandler}
          value={value}
          placeholder={placeholder}
        />
        {value && (
          <button
            type="button"
            className={css.btn}
            onClick={() => setValue('')}
          >
            <IoMdClose />
          </button>
        )}
      </label>
      <button className="btn" type="submit">
        Найти
      </button>
    </form>
  );
};
