import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  // baseURL: 'https://vsesvoi.onrender.com',
  baseURL,
});

export const setAuthHeader = token => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    api.defaults.headers.common.Authorization = '';
  }
};
