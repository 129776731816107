import io from 'socket.io-client';

let socket;

// const baseURL = process.env.REACT_APP_BASE_URL;

export const initiateSocket = token => {
  if (!socket) {
    socket = io('https://api.vsesvoi.net', {
      withCredentials: true,
      query: { token },
    });
  }
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => {
  return socket;
};
