import heic2any from 'heic2any';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { MdOutlineDone } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { BsArrowLeft } from 'react-icons/bs';
import { mediaSizes } from 'constants/constants';
import { isoDateFormatter } from '../../helpers/isoDateFormatter';
import { setIsDataShown } from 'redux/slices/auth/authSlice';
import { saveAvatar } from 'redux/slices/auth/operations';
import { showRating } from '../../helpers/showRating';
import { Loader } from '../Loader/Loader';
import { Popup } from 'components/Popup/Popup';
import {
  setAnnouncementsMessage,
  setAnnouncementsStatus,
  setIsMessagesList,
} from 'redux/slices/announcements/annoncementSlice';
import { getWord } from 'helpers/getWord';
import { toTitleCase } from 'helpers/toTitleCase';
import defaultAvatar from '../../icons/defaultAvatar.svg';
import css from './ProfileLayout.module.scss';
import { useLocalStorage } from 'hooks/useLocalStorage';

export const ProfileLayout = () => {
  const [avatar, setAvatar] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isVerifyShown, setIsVerifyShown] = useLocalStorage(
    'verifyShown',
    true
  );

  const {
    isLoading,
    message,
    status,
    unreadMessages = {},
    isMessagesList,
  } = useSelector(state => state.announcements);
  const { isDataShown } = useSelector(state => state.auth);
  const { verify } = useSelector(state => state.auth.user);
  const { _id, avatarURL, name, createdAt, responses, rating } = useSelector(
    state => state.auth.user
  );
  const dispatch = useDispatch();

  const params = useParams();
  const { type } = params;
  const location = useLocation();
  const isMessages = location.pathname.split('/').includes('messages');
  const userId = useSelector(state => state.auth.user._id);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAnnouncementsMessage(''));
      dispatch(setAnnouncementsStatus(''));
    }, 3500);
  });

  useEffect(() => {
    if (isMessages) {
      dispatch(setIsMessagesList(true));
    } else {
      dispatch(setIsMessagesList(false));
    }
  }, [isMessages, dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseInt(mediaSizes.desktop)) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeVerifyHandler = () => {
    setIsVerifyShown(false);
  };

  const typeShow = type => {
    if (type === 'active') {
      return 'Активные:';
    } else if (type === 'inactive') {
      return 'Неактивные:';
    } else if (type === 'selected') {
      return 'Избранные:';
    } else {
      return '';
    }
  };

  const openDataHandler = () => {
    dispatch(setIsDataShown(true));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const closeDataHandler = () => {
    dispatch(setIsDataShown(false));
  };

  const saveAvatarHandler = () => {
    const newAvatar = new FormData();
    newAvatar.append('avatarURL', avatar);

    dispatch(saveAvatar(newAvatar));
    setAvatar(null);
  };

  const handleImageUpload = async event => {
    let file = event.target.files[0];

    if (file.type === 'image/heic') {
      file = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.5,
      });
    }

    setAvatar(file);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Popup message={message} status={status} />
      <div className={css.wrapper}>
        <div className={css.profile}>
          <div className={css.head}>
            <div className={css.avatarWrapper}>
              <img
                src={
                  avatar
                    ? URL.createObjectURL(avatar)
                    : avatarURL || defaultAvatar
                }
                className={css.avatar}
                alt={name}
              />
              <label className={css.addAvatar}>
                <input
                  type="file"
                  className={css.file}
                  onChange={handleImageUpload}
                  accept=".jpg, .jpeg, .png, .webp, .gif, .bmp, .svg, .heic, .tif, .tiff"
                />
                <IoMdAdd />
              </label>
              {avatar && (
                <button
                  type="button"
                  className={css.saveAvatar}
                  onClick={saveAvatarHandler}
                >
                  <MdOutlineDone />
                </button>
              )}
            </div>
            <div className={css.info}>
              <p className={css.name}>{toTitleCase(name, false)}</p>
              {rating > 0 && showRating(rating)}
              <Link
                to={`/users/${_id}/profile/responses`}
                className={css.infolink}
                onClick={openDataHandler}
              >
                {responses && responses.length > 0
                  ? `${responses.length} `
                  : ''}
                {responses && getWord(responses.length, 'review')}
              </Link>
              {createdAt && (
                <span className={css.date}>
                  на сайте с {isoDateFormatter(createdAt)}
                </span>
              )}
            </div>
            {!verify && isVerifyShown && (
              <div className={css.verify}>
                Вы не подтвердили вашу почту. При регистрации Вам было
                отправлено письмо для верификации. Перейдите по ссылке в письме
                для верификации почты!
                <button
                  className={css.verifyBtn}
                  type="button"
                  onClick={closeVerifyHandler}
                >
                  Закрыть
                </button>
              </div>
            )}
          </div>
          <ul className={css.menulist}>
            <li className={css.menuitem}>
              Объявления
              <ul className={css.announcements}>
                <li>
                  <NavLink
                    to={`/users/${_id}/profile/announcements/active`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Активные
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/users/${_id}/profile/announcements/inactive`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Неактивные
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/users/${_id}/profile/announcements/selected`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Избранные
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/users/${_id}/profile/announcements/new`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Создать новое
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className={css.menuitem}>
              Сообщения
              <ul className={css.announcements}>
                <li className={css.messages}>
                  <NavLink
                    to={`/users/${_id}/profile/conversations/sell`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Продаю
                  </NavLink>
                  {unreadMessages.sell > 0 && (
                    <span className={css.unread}>{unreadMessages?.sell}</span>
                  )}
                </li>
                <li className={css.messages}>
                  <NavLink
                    to={`/users/${_id}/profile/conversations/buy`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Покупаю
                  </NavLink>
                  {unreadMessages.buy > 0 && (
                    <span className={css.unread}>{unreadMessages?.buy}</span>
                  )}
                </li>
              </ul>
            </li>
            <li className={css.menuitem}>
              Настройки
              <ul className={css.announcements}>
                <li>
                  <NavLink
                    to={`/users/${_id}/profile/settings/contacts`}
                    className={({ isActive }) =>
                      isActive ? `${css.active}` : `${css.link}`
                    }
                    onClick={openDataHandler}
                  >
                    Ваши контакты
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {isDataShown && (
          <div
            className={css.data}
            style={{
              overflowY: isMessagesList && !isMobileScreen ? 'hidden' : 'auto',
            }}
          >
            {isMobileScreen && (
              <Link
                to={`/users/${userId}/profile`}
                className={css.backbtn}
                onClick={closeDataHandler}
                title="назад"
              >
                <BsArrowLeft />
              </Link>
            )}
            <p className={css.title}>{typeShow(type)}</p>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </div>
        )}
      </div>
    </>
  );
};
