import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import css from './Navbar.module.scss';

export const Navbar = ({ onClick }) => {
  const region = useSelector(state => state.location.region);
  const city = useSelector(state => state.location.selectedCity);

  const links = [
    { name: 'Поездки', path: `${region}/${city}/posts/trips` },
    { name: 'События', path: `${region}/${city}/posts/events` },
    { name: 'Знакомства', path: `${region}/${city}/posts/meetings` },
    { name: 'Форум', path: `${region}/${city}/posts/forum` },
  ];

  return (
    <nav>
      <ul className={css.list}>
        {links.map(({ name, path }) => (
          <li className={css.item} key={name}>
            <NavLink
              to={path}
              className={({ isActive }) =>
                isActive ? `${css.active}` : `${css.link}`
              }
              onClick={onClick}
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
