import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  region: 'All',
  cities: [],
  selectedCity: 'All',
};

const locationSlice = createSlice({
  name: 'location',
  initialState: initialData,
  reducers: {
    selectRegion: {
      reducer(state, action) {
        state.region = action.payload;
      },
    },
    setCities: {
      reducer(state, action) {
        state.cities = action.payload;
      },
    },
    selectCity: {
      reducer(state, action) {
        state.selectedCity = action.payload;
      },
    },
  },
});

export const { selectRegion, setCities, selectCity } = locationSlice.actions;
export const locationReducer = locationSlice.reducer;
