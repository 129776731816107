import { api } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addNewAnnouncement = createAsyncThunk(
  'announcements/addNewAnnouncement',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post('/announcements/new', credentials);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getOwnAnnouncements = createAsyncThunk(
  'announcements/getOwnAnnouncements',
  async (credentials, thunkAPI) => {
    try {
      const { type } = credentials;

      if (type === 'selected') {
        const { data } = await api.get(`/announcements/selected`);
        return data;
      } else {
        const { data } = await api.get(`/announcements/own/${type}`);
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getAllAnnouncements = createAsyncThunk(
  'announcements/getAllAnnouncements',
  async (params, thunkAPI) => {
    try {
      const { data } = await api.get('/announcements', { params });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const searchAnnouncements = createAsyncThunk(
  'announcements/searchAnnouncements',
  async (credentials, thunkAPI) => {
    try {
      const { query = '', region = '', city = '' } = credentials;
      const { data } = await api.get(
        `/announcements/search?region=${region}&city=${city}&query=${query}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getOneAnnouncement = createAsyncThunk(
  'announcements/getOneAnnouncement',
  async (id, thunkAPI) => {
    try {
      const { data } = await api.get(`/announcements/${id}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteAnnouncement = createAsyncThunk(
  'announcements/deleteAnnouncement',
  async (credentials, thunkAPI) => {
    try {
      const { _id } = credentials;
      const { data } = await api.delete(`/announcements/${_id}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const editAnnouncement = createAsyncThunk(
  'announcements/editAnnouncement',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId } = credentials;
      const { data } = await api.get(`/announcements/${announcementId}/edit`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const updateAnnouncement = createAsyncThunk(
  'announcements/updateAnnouncement',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId, formData } = credentials;
      const { data } = await api.patch(
        `/announcements/${announcementId}`,
        formData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const addLike = createAsyncThunk(
  'announcements/addLike',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId } = credentials;
      const { data } = await api.post(`/announcements/${announcementId}/like`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const addResponse = createAsyncThunk(
  'announcements/addResponse',
  async (credentials, thunkAPI) => {
    try {
      const { id, text, rating } = credentials;
      const { data } = await api.patch(`/users/${id}/response`, {
        text,
        rating,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getOtherAnnouncements = createAsyncThunk(
  'announcements/getOtherAnnouncements',
  async (credentials, thunkAPI) => {
    try {
      const { id } = credentials;
      const { data } = await api.get(`/announcements/${id}/others`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getAnnouncementConversation = createAsyncThunk(
  'announcements/getAnnouncementConversation',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId } = credentials;
      const { data } = await api.get(
        `/conversations/messages/${announcementId}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getMessages = createAsyncThunk(
  'announcements/getMessages',
  async (credentials, thunkAPI) => {
    try {
      const { conversationId } = credentials;
      const { data } = await api.get(
        `/conversations/${conversationId}/messages`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllConversations = createAsyncThunk(
  'announcements/getAllConversations',
  async (credentials, thunkAPI) => {
    try {
      const { conversationType } = credentials;
      const { data } = await api.get(`/conversations/${conversationType}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getSendersList = createAsyncThunk(
  'announcements/getSendersList',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId } = credentials;
      const { data } = await api.get(
        `/conversations/senders/${announcementId}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getUnreadMessages = createAsyncThunk(
  'announcements/getUnreadMessages',
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get('/conversations/messages/unread');
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const changeExpirationDate = createAsyncThunk(
  'announcements/changeExpirationDate',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId } = credentials;

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 30);
      const isoFormatDate = currentDate.toISOString();

      const { data } = await api.patch(
        `/announcements/${announcementId}/expire`,
        {
          isoFormatDate,
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const activateAnnouncement = createAsyncThunk(
  'announcements/activateAnnouncement',
  async (credentials, thunkAPI) => {
    try {
      const { announcementId } = credentials;
      const { data } = await api.patch(
        `/announcements/${announcementId}/inactivate`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
