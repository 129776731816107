import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ReactComponent as Success } from 'icons/success.svg';
import { useDispatch } from 'react-redux';
import { setMessage, setStatus } from 'redux/slices/auth/authSlice';
import {
  setAnnouncementsMessage,
  setAnnouncementsStatus,
} from 'redux/slices/announcements/annoncementSlice';
import { setPostsMessage, setPostsStatus } from 'redux/slices/posts/postsSlice';
import css from './Popup.module.scss';

export const Popup = ({ message, status }) => {
  const dispatch = useDispatch();

  const popupCloseHandler = () => {
    dispatch(setMessage(''));
    dispatch(setStatus(''));
    dispatch(setPostsMessage(''));
    dispatch(setPostsStatus(''));
    dispatch(setAnnouncementsMessage(''));
    dispatch(setAnnouncementsStatus(''));
  };

  return (
    <div
      className={
        message ? `${css.popUp} ${css.active} ${css[status]}` : css.popUp
      }
    >
      <p className={css.text}>
        {message &&
          (status === 'error' ? (
            <span className={css.warning}>!</span>
          ) : (
            <Success />
          ))}
        {message}
      </p>
      <button
        type="button"
        className={css.closeBtn}
        onClick={popupCloseHandler}
      >
        <AiOutlineCloseCircle />
      </button>
    </div>
  );
};
