import { createSlice } from '@reduxjs/toolkit';
import {
  addNewAnnouncement,
  getOwnAnnouncements,
  getAllAnnouncements,
  deleteAnnouncement,
  editAnnouncement,
  updateAnnouncement,
  getOneAnnouncement,
  addLike,
  addResponse,
  getOtherAnnouncements,
  getAllConversations,
  getSendersList,
  getMessages,
  getUnreadMessages,
  changeExpirationDate,
  activateAnnouncement,
  searchAnnouncements,
  getAnnouncementConversation,
} from './operations';

const initialData = {
  announcements: [],
  ownAnnouncements: [],
  isLoading: false,
  message: '',
  status: '',
  edit: {},
  totalPages: null,
  amount: null,
  selected: [],
  announcement: {},
  conversations: [],
  conversation: {},
  senders: [],
  unreadMessages: { sell: 0, buy: 0 },
  error: false,
  isNewMessage: false,
  isMessagesList: false,
  newAnnouncement: '',
};

const announcementSlice = createSlice({
  name: 'announcements',
  initialState: initialData,
  reducers: {
    setAnnouncementsMessage: (state, action) => {
      state.message = action.payload;
    },
    setAnnouncementsStatus: (state, action) => {
      state.status = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setAnnouncementMessage: (state, action) => {
      state.message = action.payload;
    },
    setAnnouncementStatus: (state, action) => {
      state.status = action.payload;
    },
    setAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    setAnnouncementsError: (state, action) => {
      state.error = action.payload;
    },
    setIsMessagesList: (state, action) => {
      state.isMessagesList = action.payload;
    },
    clearNewAnnouncement: (state, action) => {
      state.newAnnouncement = action.payload;
    },
    getNewMessage: (state, action) => {
      state.isNewMessage = true;

      if (state.conversation._id === action.payload._id) {
        state.conversation = action.payload;
      }
    },
    getMessageSent: (state, action) => {
      state.conversation = action.payload;
    },
    setUnreadMessages: (state, action) => {
      state.unreadMessages[action.payload] =
        state.unreadMessages[action.payload] - 1;
    },
    getMessagesRead: (state, action) => {
      state.conversation = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addNewAnnouncement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newAnnouncement = action.payload._id;
        state.message = 'Объявление успешно создано!';
        state.status = 'success';
      })
      .addCase(addNewAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(getOwnAnnouncements.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOwnAnnouncements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ownAnnouncements = action.payload;
      })
      .addCase(getOwnAnnouncements.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(getAllAnnouncements.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllAnnouncements.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.announcements = payload.announcements;
        state.totalPages = payload.totalPages;
        state.amount = payload.amount;
      })
      .addCase(getAllAnnouncements.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(deleteAnnouncement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ownAnnouncements = state.ownAnnouncements.filter(
          item => item._id !== action.payload.id
        );

        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(deleteAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(editAnnouncement.pending, (state, action) => {
        state.isLoading = true;
        state.edit = {};
      })
      .addCase(editAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.edit = action.payload;
      })
      .addCase(editAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(updateAnnouncement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = 'Объявление успешно изменено!';
        state.status = 'success';
        state.edit = {};
      })
      .addCase(updateAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(addLike.fulfilled, (state, action) => {
        const index = state.announcements.findIndex(
          item => item._id === action.payload.announcement._id
        );

        if (index > -1) {
          state.announcements[index] = action.payload.announcement;
          state.announcement = action.payload.announcement;
        } else {
          state.announcement = action.payload.announcement;
        }

        if (action.payload.deleted) {
          state.ownAnnouncements = state.ownAnnouncements.filter(
            item => item._id !== action.payload.announcement._id
          );
          state.message = 'Удалено из избранных';
        } else {
          state.message = 'Добавлено в избранные';
        }
        state.status = 'info';
      })
      .addCase(addLike.rejected, (state, action) => {
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(getOneAnnouncement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOneAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.announcement = action.payload;
      })
      .addCase(getOneAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(addResponse.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.announcement = { ...state.announcement, owner: action.payload };
        state.message = 'Ваш отзыв добавлен';
        state.status = 'success';
      })
      .addCase(addResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(getOtherAnnouncements.pending, (state, action) => {
        state.isLoading = true;
        state.announcements = [];
      })
      .addCase(getOtherAnnouncements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.announcements = action.payload;
      })
      .addCase(getOtherAnnouncements.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(getAnnouncementConversation.pending, (state, action) => {
        state.isLoading = true;
        state.conversation = {};
        state.announcement = {};
      })
      .addCase(getAnnouncementConversation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.announcement = payload.announcement;
        state.conversation = payload.conversation;
      })
      .addCase(getAnnouncementConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(getMessages.pending, (state, action) => {
        state.isLoading = true;
        state.conversation = {};
        state.announcement = {};
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversation = action.payload.conversation;
        state.announcement = action.payload.announcement;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(getAllConversations.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllConversations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversations = action.payload;
      })
      .addCase(getAllConversations.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(getSendersList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSendersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.senders = action.payload;
      })
      .addCase(getSendersList.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(getUnreadMessages.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUnreadMessages.fulfilled, (state, action) => {
        state.unreadMessages = action.payload.unreadMessages;
      })
      .addCase(getUnreadMessages.rejected, (state, action) => {
        state.message = action.payload.data.message;
        state.status = 'error';
      })
      .addCase(changeExpirationDate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changeExpirationDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(changeExpirationDate.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(activateAnnouncement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(activateAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ownAnnouncements = state.ownAnnouncements.filter(
          item => item._id !== action.payload._id
        );
      })
      .addCase(activateAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.data.message;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.error = true;
        }
      })
      .addCase(searchAnnouncements.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchAnnouncements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.announcements = action.payload;
      })
      .addCase(searchAnnouncements.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      });
  },
});

export const {
  setAnnouncementsMessage,
  setAnnouncementsStatus,
  setTotalPages,
  setAnnouncements,
  getNewMessage,
  setUnreadMessages,
  setAnnouncementsError,
  setIsMessagesList,
  getMessageSent,
  getMessagesRead,
  clearNewAnnouncement,
} = announcementSlice.actions;
export const announcementReducer = announcementSlice.reducer;
