import { setWarning } from 'redux/slices/auth/authSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import css from './Warning.module.scss';

export const Warning = () => {
  const { warning } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const closeWarningHandler = () => {
    dispatch(setWarning(''));
  };

  const redirectToLoginHandler = () => {
    dispatch(setWarning(''));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={warning ? `${css.wrapper}` : `${css.wrapper} ${css.open}`}>
      <button type="button" className={css.btn} onClick={closeWarningHandler}>
        <AiOutlineClose />
      </button>
      <p className={css.text}>{warning}</p>
      {warning && (
        <Link to={'/login'} className="btn" onClick={redirectToLoginHandler}>
          Войти
        </Link>
      )}
    </div>
  );
};
