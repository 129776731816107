import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar } from '../Navbar/Navbar';
import { HiMenu } from 'react-icons/hi';
import { VscClose } from 'react-icons/vsc';
import { AiFillHome } from 'react-icons/ai';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { mediaSizes } from 'constants/constants';
import { ReactComponent as Logo } from 'icons/logo.svg';
import css from './Header.module.scss';

export const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showBurgerIcon, setShowBurgerIcon] = useState(false);

  const clickhandler = () => {
    setIsMobileMenuOpen(state => !state);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= parseInt(mediaSizes.desktop)) {
        setShowBurgerIcon(true);
      } else {
        setShowBurgerIcon(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className={`${css.head} ${isFixed ? css.fixed : ''}`}>
      <div className={css.container}>
        <div className={css.wrapper}>
          <span className={css.logo} title="Все свои">
            <Logo />
          </span>
          <NavLink to="/" title="На главную">
            <AiFillHome className={css.icon} />
          </NavLink>
        </div>
        {!showBurgerIcon && <Navbar />}
        <div className={css.wrapper}>
          <UserInfo />
          {showBurgerIcon && (
            <button type="button" className={css.btn} onClick={clickhandler}>
              <HiMenu className={css.icon} />
            </button>
          )}
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className={css.mobileMenu}>
          <button type="button" className={css.btnclose} onClick={clickhandler}>
            <VscClose className={css.close} />
          </button>
          <Navbar onClick={clickhandler} />
        </div>
      )}
    </header>
  );
};
